<template>
  <div class="overall">
    <div class="title">
      <h2>Aff Research</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="filter">
              <el-row :gutter="10">
                <el-form-item label="Account Ids" label-width="1.65rem">
                  <el-col :xs="10" :sm="6" :md="6" :lg="7" :xl="6">
                    <el-select
                      v-model="filter.accountUserNames"
                      clearable
                      multiple
                      style="width:2.4rem"
                    >
                      <el-option
                        v-for="item in accountOptions"
                        :key="item.username"
                        :label="item.username"
                        :value="item.username"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="Countries" label-width="2rem">
                  <el-col :xs="8" :sm="6" :md="6" :lg="7" :xl="6">
                    <el-input
                      v-model="filter.countries"
                      style="width:3rem"
                      placeholder="countries eg:HK,US"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item label="mmp" label-width="2.6rem">
                  <el-col :xs="10" :sm="6" :md="6" :lg="7" :xl="6">
                    <el-select v-model="filter.mmp" style="width:3rem" clearable filterable>
                      <el-option
                        v-for="item in mmpOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="TrafficTags" label-width="2rem">
                  <el-col :xs="8" :sm="6" :md="6" :lg="8" :xl="6">
                    <el-select
                      v-model="filter.trafficTags"
                      clearable
                      multiple
                      style="width:2.4rem;"
                    >
                      <el-option
                        v-for="item in trafficTagOptions"
                        :key="item.trafficTag"
                        :label="item.trafficTag"
                        :value="item.trafficTag"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="12">
                    <el-button type="primary" @click="getTableList()">In Browser</el-button>
                  </el-col>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <div>
              <el-table
                v-loading="loading"
                border
                stripe
                :data="dataList"
                style="width: 100%;margin-top:20px;"
                height="70vmin"
                highlight-current-row
              >
                <el-table-column label="Id" align="center" min-width="60" prop="id" fixed>
                </el-table-column>
                <el-table-column label="company" prop="company" align="center" min-width="130">
                </el-table-column>
                <el-table-column
                  label="username"
                  prop="uname"
                  align="center"
                  min-width="130"
                ></el-table-column>
                <el-table-column
                  label="trafficCountry"
                  prop="trafficCountry"
                  min-width="120"
                  align="center"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="trafficTag"
                  prop="trafficTag"
                  min-width="120"
                  align="center"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="MMP"
                  prop="mmp"
                  min-width="120"
                  align="center"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
              <pagination
                class="block pagePanel"
                :pageTotal="total"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :pageSize="pageSize"
                :currentPage="currentPage"
              ></pagination>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import optionData from '@/assets/js/optionData';
  import Pagination from '@/components/pagination';
  import { getAccountList } from '@/api/affiliate/affResearch';
  import { getTagList } from '@/api/affiliate/tag';
  import { getResearchList } from '@/api/affiliate/affResearch';
  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        countryOptions: optionData.countryOptions,
        accountOptions: [],
        trafficTagOptions: [],
        filter: {
          accountIds: [],
          countries: '',
          mmp: '',
          trafficTags: [],
        },
        dataList: [],
        loading: false,
        total: 0,
        pageSize: 20,
        currentPage: 1,
        mmpOptions: [
          {
            label: 'MMP only',
            value: 'MMP only',
          },
          {
            label: 'MMP',
            value: 'MMP',
          },
          {
            label: 'no_MMP',
            value: 'no_MMP',
          },
        ],
      };
    },
    methods: {
      async getAccountOptions() {
        const res = await getAccountList({
          page: 1,
          pageSize: 9999,
        });
        this.accountOptions = res.result;
      },
      async getTrafficTagOptions() {
        const res = await getTagList({
          page: 1,
          pageSize: 9999,
        });
        this.trafficTagOptions = res.result;
      },
      async getTableList(page) {
        if (page) {
          this.currentPage = page;
        }
        try {
          const params = {
            page: this.currentPage,
            pageSize: this.pageSize,
          };
          Object.keys(this.filter).forEach((key) => {
            const value = this.filter[key];
            if (Array.isArray(value)) {
              params[key] = value.join(',');
            } else {
              params[key] = value;
            }
          });
          const res = await getResearchList(params);
          this.dataList = res.result;
          this.total = res.total;
          this.pageSize = res.pageSize;
          this.currentPage = res.currentPage;
        } catch (error) {
          console.log(error);
        }
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.getTableList();
      },
    },
    beforeMount() {
      this.getAccountOptions();
      this.getTrafficTagOptions();
    },
  };
</script>

<style lang="scss" scoped></style>
