import request from 'utils/request';

const api = {
  TAG_LIST: '/api/affiliate/trafficTag/list',
  INSERT_TAG: '/api/affiliate/trafficTag/add',
  DELETE_TAG: '/api/affiliate/trafficTag/delete',
  UPDATE_TAG: '/api/affiliate/trafficTag/update',
  All_TAG: '/api/affiliate/trafficTag/tags',
};

export function getTagList(params) {
  return request({ url: api.TAG_LIST, method: 'get', params });
}
export function addTag(data) {
  return request({ url: api.INSERT_TAG, method: 'post', data });
}
export function deleteTag(data) {
  return request({ url: api.DELETE_TAG, method: 'delete', data });
}
export function updateTag(data) {
  return request({ url: api.UPDATE_TAG, method: 'put', data });
}
export function getAllTags(status) {
  return request({ url: api.All_TAG, method: 'get', params: { status } });
}
