import request from 'utils/request';

const api = {
  RESEARCH_LIST: '/api/affiliate/research/list',
  ACCOUNT_LIST: '/api/affiliate/research/getAmSysAccount',
};
export function getResearchList(query) {
  return request({
    url: api.RESEARCH_LIST,
    method: 'get',
    params: query,
  });
}
export function getAccountList(query) {
  return request({
    url: api.ACCOUNT_LIST,
    method: 'get',
    params: query,
  });
}
